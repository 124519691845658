import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import config from '@config'
import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams, SubmitFormEvent } from '@hooks/useBookingFlow'
import DlocalCardForm, { CardFormRef } from '@pages/Checkout/hooks/Payment/dLocal/Card/Form'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import PaymentLabel from '@pages/Checkout/Payment/Label'
import { useParams } from '@stores/params'

const buildDlocal = () => (window.dlocal ? new window.dlocal(config.dlocal.publicKey) : null)

interface DLocalCardAction {
  redirect: string
}

export const useCard = (): PaymentMethod => {
  const [{ locale }] = useParams()
  const [dlocal, setDlocal] = useState(buildDlocal)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const form = useMemo(() => dlocal?.fields({ locale: locale.slice(0, 2), country: 'MX' }), [dlocal])
  const formRef = useRef<CardFormRef>(null)

  const getOption = useCallback(
    () => ({
      value: 'credit_card',
      label: <PaymentLabel type="credit_card" showName />,
      content: form && <DlocalCardForm form={form} ref={formRef} />,
    }),
    [form],
  )

  // istanbul ignore next
  const confirmPayment = useCallback((response: ConfirmPaymentParams) => {
    // better type support is implemented in https://github.com/distribusion/owl-frontend/pull/1051
    const actionData = response.details?.action as unknown as DLocalCardAction
    if (response.action === 'three_d_secure' && actionData?.redirect) {
      window.location.href = actionData.redirect
    }
  }, [])

  const submitForm = useCallback<SubmitFormEvent>(
    async ({ shopperDocument }) => {
      /* istanbul ignore else */
      if (dlocal && formRef.current) {
        const { token } = await dlocal.createToken(formRef.current.field, { name: shopperDocument })

        return { paymentMethodData: { type: 'CARD', flow: 'DIRECT', country: 'MX', token } }
      }
    },
    [dlocal],
  )

  const onLoad = useCallback(() => {
    setDlocal(buildDlocal())
  }, [])

  useEffect(() => {
    /* istanbul ignore next */
    if (document.head.querySelector(`script[src="${config.dlocal.scriptSrc}"]`) !== null) return

    const script = document.createElement('script')
    script.src = config.dlocal.scriptSrc
    script.onload = onLoad
    document.head.appendChild(script)
  }, [onLoad])

  return useMemo(
    () => ({
      getOption,
      provider: 'dlocal',
      status: dlocal ? PaymentMethodStatus.Ready : PaymentMethodStatus.Pending,
      on: {
        confirmPayment,
        submitForm,
        validate: () => (formRef.current?.isValid ? {} : { paymentMethodData: 'Invalid card fields' }),
        beforeSubmit: () => formRef.current?.setTouched(true),
      },
    }),
    [confirmPayment, dlocal, getOption, submitForm],
  )
}
