import { Field, useFormikContext } from 'formik'
import React, { ReactElement, useState } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { email, password, required, exact, chain } from '@lib/validators'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Icon, Modal, Tooltip } from '@ui'
import InputField from '@ui/Input/Field'

import '@pages/Checkout/AccountCreation/index.scss'

const AccountCreation = (): ReactElement => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const {
    values: { account },
  } = useFormikContext<CheckoutFormData>()

  const toggleInputType = (isVisible: boolean) => (isVisible ? 'text' : 'password')

  const handleOpenModal = (): void => {
    isMobile && setIsModalOpen(!isModalOpen)
  }

  const tooltipContent = !isMobile ? <>{t('checkout.createAccount.tooltip.content')}</> : null

  return (
    <div className="account-creation">
      <div className="row space-between items-center">
        <h3 className="mb-1">{t('checkout.createAccount.title')}</h3>
        <Tooltip
          content={tooltipContent}
          action="hover"
          position="top-end"
          maxWidth="md"
          popperClassName={bem('account-creation', 'tooltip')}
        >
          <Icon name="info" size="medium" onClick={handleOpenModal} />
        </Tooltip>
        {isMobile && (
          <Modal opened={isModalOpen} onClose={handleOpenModal} title={t('checkout.createAccount.tooltip.title')}>
            <div className={bem('account-creation', 'modal')}>{t('checkout.createAccount.tooltip.content')}</div>
          </Modal>
        )}
      </div>
      <p className="mb-5 account__description">{t('checkout.createAccount.description')}</p>
      <div className="row gap-3 column-sm wrap">
        <div className="cell-6 cell-sm-12">
          <Field
            component={InputField}
            label={t('checkout.createAccount.email')}
            name="account.email"
            validate={chain([required, email])}
            required
          />
        </div>
        <div className="row gap-3 wrap">
          <div className="cell-6 cell-sm-12">
            <Field
              component={InputField}
              label={t('checkout.createAccount.password')}
              name="account.password"
              type={toggleInputType(showPassword)}
              autoComplete="new-password" // this prevents autofill on iOS interfering with password masking
              iconAfter={
                <div className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                  <Icon name={showPassword ? 'eye-closed' : 'eye-open'} size="large" />
                </div>
              }
              validate={chain([required, password])}
              required
            />
          </div>
          <div className="cell-6 cell-sm-12">
            <Field
              component={InputField}
              label={t('checkout.createAccount.repeatPassword')}
              name="account.repeatPassword"
              type={toggleInputType(showRepeatPassword)}
              autoComplete="new-password" // this prevents autofill on iOS interfering with password masking
              iconAfter={
                <div className="password-toggle" onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                  <Icon name={showRepeatPassword ? 'eye-closed' : 'eye-open'} size="large" />
                </div>
              }
              validate={chain([required, exact(account.password, t('errors.invalidPasswordMatch'))])}
              required
            />
          </div>
        </div>
        <div className="row cell-6 cell-sm-12 password-info">
          <Icon name="info" size="medium" className={bem('password-info', 'icon')} />
          <p className={bem('password-info', 'text')}>{t('checkout.createAccount.hint')}</p>
        </div>
      </div>
    </div>
  )
}

export default AccountCreation
