import React, { FunctionComponent } from 'react'

import AncillarySelect from '@pages/Checkout/Extras/Ancillary/components/Controls/Select'
import InlineAncillary from '@pages/Checkout/Extras/Ancillary/components/Inline'
import Ancillary from '@pages/Checkout/Extras/Ancillary/index'
import InsuranceAncillary from '@pages/Checkout/Extras/Ancillary/Insurance'
import { ResolverProps } from '@pages/Checkout/Extras/Ancillary/Resolver'

const PETS = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const INSURANCE = () => <InsuranceAncillary />
const SIMPLIFIED_INSURANCE = (props: ResolverProps) => <InlineAncillary {...props} controls={<AncillarySelect />} />
const EQUIPMENT = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const BICYCLE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const HELP = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const SEAT = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const LUGGAGE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const VEHICLE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const MEAL = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const METRO = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const LEAD = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const BOARDING = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const LOUNGE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const FASTTRACK = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const TICKETSBYPOST = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const CHANGE = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const YOUNGCOACHCARD = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const SENIORCOACHCARD = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const DISABLEDCOACHCARD = (props: ResolverProps) => <Ancillary.Resolver {...props} />
const FREESEAT = (props: ResolverProps) => <Ancillary.Resolver {...props} />

const CARBON_SWITCH_ANCILLARIES = ['OFFSET', 'GEBOFFSET']
const CARBON = (props: ResolverProps) => {
  const isSwitchControl = props.ancillaries?.some(({ code }) => CARBON_SWITCH_ANCILLARIES.includes(code))

  return <Ancillary.Extended {...props} control={isSwitchControl ? 'switch' : 'counter'} />
}

const Ancillaries: Record<string, FunctionComponent<ResolverProps>> = {
  PETS,
  INSURANCE,
  SIMPLIFIED_INSURANCE,
  EQUIPMENT,
  BICYCLE,
  HELP,
  SEAT,
  LUGGAGE,
  VEHICLE,
  MEAL,
  METRO,
  LEAD,
  BOARDING,
  LOUNGE,
  FASTTRACK,
  TICKETSBYPOST,
  CHANGE,
  YOUNGCOACHCARD,
  SENIORCOACHCARD,
  DISABLEDCOACHCARD,
  FREESEAT,
  CARBON,
}

export default Ancillaries
